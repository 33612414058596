import React from 'react';
import { Secao } from '@alweb-merkator/shared/components/Secao';
import Banner from '@alweb-merkator/shared/components/Banner';

const SecaoBanner = ({ ...props }) => {
  return (
    <Secao {...props}>
      <Banner idEvent={2} />
    </Secao>
  );
};

export default SecaoBanner;
