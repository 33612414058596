import React from 'react';
import ThemeProviderShared from '@alweb-merkator/shared/providers/ThemeProvider';

import zeroGrauTheme from '../styles/themes/zeroGrau';

const ThemeProvider = (props) => (
  <ThemeProviderShared currentTheme={zeroGrauTheme} {...props} />
);

export default ThemeProvider;
