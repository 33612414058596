import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Col,
  Row,
  Secao,
  SecaoBotaoVoltar,
  SecaoCabecalho,
  SecaoTitulo,
  Conteudo,
  Box,
  ImageSvg,
  LinkPlanta,
} from './styles';
import { useTranslation } from 'react-i18next';
import blNewsService from '../../../services/blNewsService';

function SecaoPlantaDaFeira({ idPage, imagemPlanta, showPlantaBaixa }) {
  const { t } = useTranslation();
  const secaoId = t('slugs.plantaBaixa');
  const { getPaginas } = blNewsService();
  const [dadosPagina, setDadosPagina] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const { items } = await getPaginas(idPage);
      if (items && items.length > 0) {
        setDadosPagina(items[0]);
      }
      setIsLoading(false);
    })();
  }, []);

  return (
    <Secao id={secaoId} className="mb-4">
      <SecaoCabecalho className="mb-4">
        <SecaoTitulo>{isLoading ? '' : dadosPagina.title}</SecaoTitulo>
        <SecaoBotaoVoltar />
      </SecaoCabecalho>
      {!showPlantaBaixa && (
        <Conteudo
          className="mb-4"
          dangerouslySetInnerHTML={{
            __html: t('paginas.inicial.secaoExpositores.subTituloBreve'),
          }}
        />
      )}
      {showPlantaBaixa && (
        <Row>
          <Col md="12">
            {isLoading ? (
              ''
            ) : dadosPagina.image ? (
              <>
                <LinkPlanta
                  href="http://paineldoexpositor.com.br/uploads/media/media_672c92f13b24f2_90431377.pdf"
                  target="_blank"
                  dangerouslySetInnerHTML={{
                    __html: t('paginas.inicial.secaoPlantaDaFeira.description'),
                  }}
                ></LinkPlanta>
                <Box>
                  <ImageSvg
                    src={dadosPagina.image}
                    alt={dadosPagina.image_description}
                  />
                </Box>
              </>
            ) : (
              <Conteudo
                dangerouslySetInnerHTML={{
                  __html: isLoading ? '' : dadosPagina.content,
                }}
              />
            )}
          </Col>
        </Row>
      )}
    </Secao>
  );
}

SecaoPlantaDaFeira.propTypes = {
  idPage: PropTypes.any,
  imagemPlanta: PropTypes.any,
};

export default SecaoPlantaDaFeira;
