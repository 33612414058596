import React from 'react';
import SecaoCidadeExposicao from '@alweb-merkator/shared/pages/PaginaInicial/SecaoCidadeExposicao';
import { useTranslation } from 'react-i18next';

function SecaoGramado(props) {
  const { t } = useTranslation();

  return <SecaoCidadeExposicao slugKey={t('slugs.gramado')} {...props} />;
}

export default SecaoGramado;
