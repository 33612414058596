import React from 'react';
import SecaoLocalExposicao from '@alweb-merkator/shared/pages/PaginaInicial/SecaoLocalExposicao';
import { useTranslation } from 'react-i18next';

function SecaoSerraPark(props) {
  const { t } = useTranslation();

  const googleMapsUrl =
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3475.9002636016253!2d-50.86752368489983!3d-29.402473582120333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x951c1ac99682b6db%3A0xb597beb79145b500!2sSerra%20Park%20-%20Centro%20de%20Feiras%20e%20Eventos!5e0!3m2!1spt-BR!2sbr!4v1615657132542!5m2!1spt-BR!2sbr';

  return (
    <SecaoLocalExposicao
      googleMapsUrl={googleMapsUrl}
      slugKey={t('slugs.serraPark')}
      {...props}
    />
  );
}

export default SecaoSerraPark;
