import { Link } from 'react-router-dom';
import styled from 'styled-components';

export { Col, Row } from 'reactstrap';

export { Tradutor } from '../../../components/I18n';

export {
  Secao,
  SecaoBotaoVoltar,
  SecaoCabecalho,
  SecaoTitulo,
} from '../../../components/Secao';

export const Caixa = styled.div`
  padding: 50px 15px;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.textInBg};
  text-align: center;
  margin-bottom: 15px;
`;

export const Titulo = styled.h3`
  color: ${(props) => props.theme.colors.textInBg};
  font-size: 1.4rem;
`;

export const BoxImagem = styled.div`
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Imagem = styled.img`
  max-width: 160px;
`;

export const Conteudo = styled.div`
  font-size: 0.9rem;
  font-weight: bold;

  @media (min-width: 768px) {
    min-height: 200px;
    padding: 0 20px;
  }
`;

export const Botao = styled(Link).attrs((props) => ({
  className: `btn btn-secondary ${props.className}`,
}))`
  display: inline-block;
  border-radius: 0.5rem;
  height: 65px;
  padding: 17.5px 30px;
`;

export const BotaoLinkExterno = styled.a.attrs((props) => ({
  className: `btn btn-secondary ${props.className}`,
}))`
  display: inline-block;
  border-radius: 0.5rem;
  height: 65px;
  padding: 17.5px 30px;
  background-color: ${(props) => props.theme.colors.secundary};
`;

export const TextoBotao = styled.p`
  margin: 0;
  font-size: 0.95rem;
`;

export const SubTextoBotao = styled(TextoBotao)`
  font-size: 0.45rem;
`;
