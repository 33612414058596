import React, { useMemo, useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import CabecalhoShared from '@alweb-merkator/shared/components/CabecalhoFeiras';
import LogoImagePt from '../../assets/logo.svg';
import LogoImageEs from '../../assets/logo-es.svg';
import LogoImageEn from '../../assets/logo-en.svg';

const Cabecalho = () => {
  const { t } = useTranslation();
  const { lang } = useParams();

  const [logoImage, setLogoImage] = useState();

  function setLogoLanguage() {
    switch (lang) {
      case 'es':
        setLogoImage(LogoImageEs);
        break;
      case 'en':
        setLogoImage(LogoImageEn);
        break;
      default:
        setLogoImage(LogoImagePt);
    }
  }

  useEffect(() => {
    setLogoLanguage();
  }, []);

  const medias = [
    {
      socialMedia: 'facebook',
      link: '//www.facebook.com/feirazerograu',
    },
    {
      socialMedia: 'instagram',
      link: '//www.instagram.com/feirazerograu',
    },
    {
      socialMedia: 'tiktok',
      link: '//www.tiktok.com/@feirazerograu?_t=8W7IIYDUQdB&_r=1',
    },
    {
      socialMedia: 'apple',
      link: '//apps.apple.com/us/app/merkator-feiras-e-eventos/id1487335018',
    },
    {
      socialMedia: 'googlePlay',
      link: '//play.google.com/store/apps/details?id=com.ricardoaguiar.appmerkatorexpo',
    },
  ];

  const menuItem = (text, link, subMenus = [], externalLink = false) => ({
    text: text,
    link: link,
    subMenus,
    externalLink,
  });

  const menu = useMemo(() => {
    const myMenu = [
      menuItem(t('cabecalho.sobreAFeira'), `/${lang}#${t('slugs.feira')}`, [
        menuItem(t('cabecalho.feira'), `/${lang}#${t('slugs.feira')}`),
        menuItem(t('cabecalho.gramado'), `/${lang}#${t('slugs.gramado')}`),
        menuItem(t('cabecalho.serraPark'), `/${lang}#${t('slugs.serraPark')}`),
        menuItem(t('cabecalho.parceiros'), `/${lang}#${t('slugs.parceiros')}`),
        menuItem(
          t('cabecalho.grupoDeImportadores'),
          `/${lang}#${t('slugs.grupoDeImportadores')}`
        ),
      ]),
      menuItem(
        t('cabecalho.queroVisitar'),
        `/${lang}#${t('slugs.queroVisitar')}`,
        [
          menuItem(
            t('cabecalho.hospedagem'),
            `/${lang}#${t('slugs.queroVisitar')}`
          ),

          menuItem(
            t('cabecalho.traslado'),
            `/${lang}#${t('slugs.queroVisitar')}`
          ),

          menuItem(
            t('cabecalho.credenciamento'),
            `/${lang}#${t('slugs.queroVisitar')}`
          ),

          menuItem(
            t('cabecalho.agenciaDeTurismo'),
            `/${lang}/#${t('slugs.agenciaDeTurismo')}`
          ),

          menuItem(
            t('cabecalho.expositores'),
            `/${lang}/#${t('slugs.expositores')}`
          ),

          menuItem(
            t('cabecalho.plantaBaixa'),
            `/${lang}/#${t('slugs.plantaBaixa')}`
          ),
        ]
      ),
      menuItem(t('cabecalho.eMerkator'), `/${lang}#${t('slugs.eMerkator')}`, [
        menuItem(
          t('cabecalho.eMerkatorTalks'),
          `/${lang}/#${t('slugs.eMerkator')}`
        ),
        menuItem(
          t('cabecalho.eMerkatorBlog'),
          `/${lang}/#${t('slugs.eMerkator')}`
        ),
        menuItem(
          t('cabecalho.eMerkatorTV'),
          `/${lang}/#${t('slugs.eMerkator')}`
        ),
        menuItem(
          t('cabecalho.eMerkatorCast'),
          `/${lang}/#${t('slugs.eMerkator')}`
        ),
        menuItem(
          t('cabecalho.eMerkatorPush'),
          `/${lang}/#${t('slugs.eMerkator')}`
        ),
      ]),
      menuItem(t('cabecalho.imprensa'), `/${lang}#${t('slugs.imprensa')}`, [
        menuItem(t('cabecalho.noticias'), `/${lang}/${t('slugs.noticias')}`),
        menuItem(
          t('cabecalho.galeriaDeImagens'),
          `/${lang}/${t('slugs.galeriaDeImagens')}`
        ),
      ]),
      menuItem(
        t('cabecalho.contato'),
        'https://merkatorfeiras.com.br/contato',
        [],
        true
      ),
    ];

    return myMenu;
  }, [t, lang]);

  return <CabecalhoShared medias={medias} menu={menu} logo={logoImage} />;
};

export default Cabecalho;
