import React, { useMemo } from 'react';
import { Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Galerias from '@alweb-merkator/shared/pages/Galerias';
import Galeria from '@alweb-merkator/shared/pages/Galeria';
import Noticias from '@alweb-merkator/shared/pages/Noticias';
import Noticia from '@alweb-merkator/shared/pages/Noticia';
import EMerkatorTv from '@alweb-merkator/shared/pages/EMerkatorTv';
import Expositores from '@alweb-merkator/shared/pages/Expositores';
import Emerkatortalks from '@alweb-merkator/shared/pages/Programacoes';
import Programacao from '@alweb-merkator/shared/pages/Programacao';
import Pagina404 from '@alweb-merkator/shared/pages/Pagina404';
import Credenciamento from '@alweb-merkator/shared/pages/Credenciamento';
import Hospedagem from '@alweb-merkator/shared/pages/Hospedagem';

import TemplateRoute from '@alweb-merkator/shared/routes/TemplateRoute';
import MultiLanguageRoute from '@alweb-merkator/shared/routes/MultiLanguageRoute';

import PaginaInicial from '../pages/PaginaInicial';
import Traslado from '../pages/Traslado';
import Protocolo from '../pages/Protocolo';
import Template from '../Template';

const Routes = () => {
  const { t, i18n } = useTranslation();
  const renderComponent = (Comp) => {
    const renderTemplateRoute = (props) => (
      <TemplateRoute Template={Template} {...props} Comp={Comp} />
    );

    return renderTemplateRoute;
  };

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const routes = useMemo(() => {
    const createRoute = (page, path, exact = false) => ({
      page,
      path,
      exact,
    });

    return [
      createRoute(PaginaInicial, '/:lang', true),
      createRoute(Galerias, `/:lang/${t('slugs.galeriaDeImagens')}`, true),
      createRoute(
        Galeria,
        `/:lang/${t('slugs.galeriaDeImagens')}/:categoriaSlug`
      ),
      createRoute(Noticias, `/:lang/${t('slugs.noticias')}`, true),
      createRoute(Credenciamento, `/:lang/${t('slugs.credenciamento')}`, false),
      createRoute(Hospedagem, `/:lang/${t('slugs.hospedagem')}`, false),
      createRoute(Noticia, `/:lang/${t('slugs.noticias')}/:noticiaSlug`),
      createRoute(Traslado, `/:lang/${t('slugs.traslado')}`),
      createRoute(Expositores, `/:lang/${t('slugs.expositores')}`, true),
      createRoute(
        Expositores,
        `/:lang/${t('slugs.expositores')}/:categoria_slug`
      ),
      createRoute(Emerkatortalks, `/:lang/${t('slugs.programacoes')}`, true),
      createRoute(EMerkatorTv, `/:lang/${t('slugs.eMerkatorTV')}`, true),
      createRoute(Protocolo, `/:lang/${t('slugs.protocolo')}`, true),
      createRoute(
        Programacao,
        `/:lang/${t('slugs.programacoes')}/:id/:programacao_slug`
      ),
      createRoute(Pagina404, '/:lang/*'),
    ];
  }, [t]);

  return (
    <Switch>
      <MultiLanguageRoute
        changeLanguage={changeLanguage}
        i18nCurrentLang={i18n.language}
        path="/"
        exact
      />
      {routes.map(({ page, path, exact }, key) => (
        <MultiLanguageRoute
          key={key}
          changeLanguage={changeLanguage}
          i18nCurrentLang={i18n.language}
          path={path}
          exact={exact}
          component={renderComponent(page)}
        />
      ))}
    </Switch>
  );
};

export default Routes;
