import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import StoreProvider from '@alweb-merkator/shared/store';
import I18nProvider from './I18nProvider';
import RoutesProvider from './RoutesProvider';
import ThemeProvider from './ThemeProvider';

function AppProvider({ children }) {
  useEffect(() => {
    localStorage.setItem('SITE_ID', 2);
  }, []);

  return (
    <StoreProvider>
      <I18nProvider>
        <ThemeProvider>
          <RoutesProvider>{children}</RoutesProvider>
        </ThemeProvider>
      </I18nProvider>
    </StoreProvider>
  );
}

AppProvider.propTypes = {
  children: PropTypes.any,
};

export default AppProvider;
