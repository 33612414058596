export default {
  title: 'zeroGrau',

  colors: {
    primary: '#67A9CE',
    secondary: '#0d2580',
    tertiary: '#b2d3e6',
    text: '#A6A4A7',
    textInBg: '#fff',
    borders: '#707070',
    borderBotaoEMerkator: '#ffffff',
    boxShadow: '#000000',
  },
  transparentColors: {
    primary: 'rgba(103,169,206,0.5)',
    secondary: 'rgba(13,37,138,0.7)',
  },
};
