import React from 'react';
// import SecaoQueroVisitarShared from '@alweb-merkator/shared/pages/PaginaInicial/SecaoQueroVisitar';
import SecaoQueroVisitarShared from '@alweb-merkator/shared/pages/PaginaInicial/SecaoQueroVisitar';
import iconeCredenciamento from '../../../assets/icone-credenciamento.png';

const SecaoQueroVisitar = ({ ...props }) => {
  return (
    <SecaoQueroVisitarShared
      iconeCredenciamento={iconeCredenciamento}
      {...props}
    />
  );
};

export default SecaoQueroVisitar;
